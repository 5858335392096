<template>
  <div id="signup-form">
    <h1 class="title has-text-centered">Sign Up</h1>
    <form @submit.prevent="signup">
      <b-field label="Email">
        <FormulateInput
          v-model="user.emailAddress"
          type="email"
          name="email"
          validation="required|email"
          placeholder="first_last@example.com"
        />
      </b-field>

      <b-field label="Password">
        <FormulateInput
          v-model="user.password"
          type="password"
          name="password"
          placeholder="••••••••••"
          validation="required|min:6,length"
          validation-name="Password"
        />
      </b-field>

      <b-field>
        <b-checkbox v-model="agreedToTOSandPrivacy" class="checkbox" />
        <p>
          I agree to the
          <router-link to="/tos" @click.native="closeModal()"
          >Terms of Service</router-link
          >
          and
          <router-link to="/privacy" @click.native="closeModal()"
          >Privacy Policy</router-link
          >
        </p>
      </b-field>

      <p v-for="err in errors" :key="err" class="error">{{ err }}</p>

      <p class="control">
        <button
          id="submitbutton"
          :class="{ 'is-loading': isLoading }"
          class="button is-primary"
        >
          Signup
        </button>
      </p>

      <router-link
        class="helpful-links button is-info is-inverted"
        to="/login"
        @click.native="closeModal()"
      >Login Here</router-link
      >
      <router-link
        class="helpful-links button is-info is-inverted"
        to="/reset"
        @click.native="closeModal()"
      >Forget your password?</router-link
      >
    </form>
  </div>
</template>

<script>
export default {
  name: "Signupform",
  components: {},
  mixins: [],
  props: {
    payload: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      agreedToTOSandPrivacy: false,
      user: {
        emailAddress: null,
        password: null,
        firstName: null,
        lastName: null,
      },
      plans: [],
      selectedPlan: null,
      errors: [],
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  computed: {},
  methods: {
    signup() {
      this.errors = []
      if (!this.agreedToTOSandPrivacy) {
        this.errors = [
          "You must agree to the Terms of Service and Privacy Policy",
        ]
        return
      }
      this.isLoading = true
      this.$accountAPI
        .signup({
          emailAddress: this.user.emailAddress,
          password: this.user.password,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
        })
        .then(() => {
          this.isLoading = false
          this.$buefy.toast.open({
            duration: 2000,
            message: "Signup Successful",
            position: "is-top",
            type: "is-success",
          })
          this.$emit("exitModal")

          if (this.payload != null && this.payload.postRoute) {
            this.$router.push(this.payload.postRoute)
          } else {
            this.$router.push("/billing")
          }
        })
        .catch((e) => {
          this.errors = ["Signup failed"]
          this.isLoading = false
          console.log(e)
          if (e.response && e.response.status === 409) {
            this.errors = ["Account with this email already exists"]
          }
        })
    },
    closeModal() {
      this.$emit("exitModal")
    },
  },
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {
    this.errors = []
  },
}
</script>

<style lang="scss" scoped>
#signup-form {
  margin: 20px;
  margin-left: 30px;
  margin-right: 30px;
  width: 500px;
}

#submitbutton {
  margin-top: 9px;
}

.error {
  color: red;
  font-size: 0.8em;
}

.helpful-links {
  margin-top: 20px;
  margin-right: 10px;
  font-size: 0.9em;
}

.price-selection {
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;

  cursor: pointer;
  .columns {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;

    .priceData {
      display: flex;
      align-items: center;
    }

    .title {
      margin: 0;
      padding: 0;
    }

    .planPrice {
      padding-left: 4px;
      padding-right: 4px;
    }
    .heading {
      margin: 0;
      padding: 0;
      display: inline;
    }
  }
}
.selected-plan {
  // background-image: linear-gradient(
  //   to right,
  //   #335eea 40%,
  //   mix(#00f2fe, #17a4ea, 50%) 110%
  // );
  border: 1px solid $primary;

  .title,
  .heading,
  p {
    color: $primary;
  }
}
@media (max-width: 700px) {
  #signup-form {
    margin: 10px;
    width: auto;
  }
}
</style>
